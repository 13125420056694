import { Button, FormGroup, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../../../component/input";
import Services from "../../../../api/services";
import Swal from "sweetalert2";
import { Stack } from "@mui/system";

interface sinNumberProps {
  id: number;
  sin_number: string;
  allow_background_check: number;
}

interface sinProps {
  handleClose: (value: boolean) => void;
}

const SinNumber: React.FC<sinProps> = ({ handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<sinNumberProps>>({});
  const [formData, setFormData] = useState<sinNumberProps>({
    id: 0,
    sin_number: "",
    allow_background_check: 1,
  });

  const validateForm = () => {
    const newErrors: Partial<sinNumberProps> = {};

    // SIN Number Validation
    if (!formData.sin_number.trim()) {
      newErrors.sin_number = "SIN Number is required";
    } else if (!/^\d{9}$/.test(formData.sin_number)) {
      newErrors.sin_number =
        "SIN Number must be exactly 9 digits and contain no letters or special characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);

      var data = new FormData();
      data.append("sin_number", formData.sin_number);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateSINNumberService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "SIN number has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const fetchSinNumber = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getSINNumberService(options);

        setFormData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSinNumber();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>SIN Number</FormLabel>
          <Input
            type="number"
            name="sin_number"
            value={formData.sin_number}
            onChange={handleInputChange}
          />
          {errors.sin_number && (
            <Typography color="error">{errors.sin_number}</Typography>
          )}
        </FormGroup>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {formData.id !== 0 &&
          (formData.allow_background_check === 1 ||
            formData.allow_background_check === 2) ? (
            <Typography variant="h6" sx={{ mt: 2, color: "red" }}>
              Note: Documents is under review yet
            </Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={
                (formData.id !== 0 &&
                  (formData.allow_background_check === 1 ||
                    formData.allow_background_check === 2)) ||
                isSubmitted
              }
            >
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default SinNumber;
