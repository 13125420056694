import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, Container, Stack } from "@mui/system";
import { IMAGES } from "../../../../assets/images";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Drawer from "../../component/Drawer";
import RejectService from "./reject-service";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Services from "../../../../api/services";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const providerMenu = [
  {
    id: 1,
    image: IMAGES.User,
    url: "profile",
    title: "Profile",
  },
  {
    id: 2,
    image: IMAGES.Availability,
    url: "availability",
    title: "Availability",
  },
  {
    id: 3,
    image: IMAGES.CustomerService,
    url: "services",
    title: "My Services",
  },
  {
    id: 4,
    image: IMAGES.Earnings,
    url: "earnings",
    title: "Earnings",
  },
  {
    id: 5,
    image: IMAGES.Documentation,
    url: "documents",
    title: "Documents",
  },
  {
    id: 6,
    image: IMAGES.ServiceHistory,
    url: "service-history",
    title: "Service History",
  },
  {
    id: 7,
    image: IMAGES.Rating,
    url: "reviews",
    title: "Reviews",
  },
];

const thumbsContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb: React.CSSProperties = {
  display: "inline-flex",
  position: "relative",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner: React.CSSProperties = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img: React.CSSProperties = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface FileWithPreview {
  name: string;
  preview: string;
}

export interface FormDataProps {
  uploadImage?: any;
}

type AcceptedItems = {
  [key: string]: boolean;
};

const ServiceProviderDashboard = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const userType = useSelector((state: any) => state.userData.user_type);
  const [error, setErrors] = useState<Partial<FormDataProps>>({});
  const [upcomingServices, setUpcomingServices] = useState([]);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [rejectService, setRejectService] = useState(false);
  const [serviceBookingId, setServiceBookingId] = useState(0);
  const [acceptedItems, setAcceptedItems] = useState<AcceptedItems>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>({
    uploadImage: [],
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      console.log("acceptedFiles>>>", acceptedFiles);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setFormData((prevData) => ({ ...prevData, uploadImage: acceptedFiles }));
      setErrors((prevErrors) => ({ ...prevErrors, uploadImage: "" }));
    },
  });

  console.log(files);
  const handleRejectService = (bookingId: number) => {
    setServiceBookingId(bookingId);
    setRejectService(!rejectService);
  };

  const capturePaymentIntent = async (bookingId: any) => {
    const response = await fetch(
      `${API_BASE_URL}/stripe/capture-payment-intent`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ bookingId }),
      }
    );
    const data = await response.json();
    return data;
  };

  const handleAcceptService = async (bookingId: number) => {
    try {
      setAcceptedItems((prevState) => ({
        ...prevState,
        [bookingId]: true,
      }));

      const captureResult = await capturePaymentIntent(bookingId);

      if (
        captureResult.paymentIntent === 1 ||
        captureResult.paymentIntent.status === "succeeded"
      ) {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const data = {
            status: "accepted",
          };
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          await Services.provider.updateUpcomingServicesData(
            bookingId,
            data,
            options
          );
          Swal.fire("Accepted!", "Service has been Accepted.", "success").then(
            async (result) => {
              if (result.isConfirmed) {
                fetchUpcomingServicesData();
              }
            }
          );
        }
      } else {
        console.log("getting error in payment", captureResult.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setAcceptedItems((prevState) => ({
        ...prevState,
        [bookingId]: false,
      }));
    }
  };

  const deleteImg = (index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      uploadImage: prevData.uploadImage.filter((_: any, i: any) => i !== index),
    }));
  };

  const thumbs = formData.uploadImage
    ? formData.uploadImage.map((file: any, index: number) => (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img
              alt="preview"
              src={file.preview}
              style={img}
              // Revoke data uri after image is loaded
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
              onClick={() => deleteImg(index)} // Call deleteImg with index
            />
          </div>
          <IconButton
            onClick={() => deleteImg(index)} // Call deleteImg with index
            disableFocusRipple
            size="small"
            sx={{
              zIndex: 5,
              color: "#FFF",
              position: "absolute",
              top: "0px",
              right: "0px",
              background: "#11b4f5",
              "&:hover": {
                background: "#11b4f5",
              },
            }}
          >
            <CloseIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        </div>
      ))
    : [];

  const fetchUpcomingServicesData = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.provider.getUpcomingServicesData(
          options
        );
        setUpcomingServices(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClickSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    var bodyFormData = new FormData();
    formData.uploadImage.forEach((image: any) => {
      bodyFormData.append("image", image);
    });

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await Services.provider.postSliderImageService(
        bodyFormData,
        options
      );

      if (response) {
        Swal.fire({
          title: "Thank you",
          text: "Image uploaded successfully",
          icon: "success",
          confirmButtonColor: "#11b4f5",
        }).then((result) => {
          if (result.isConfirmed) {
            setFormData({
              uploadImage: [],
            });
          }
        });
      }
    } catch (error: any) {
      console.log("error", error);
      if (error.response && error.response.data && error.response.data.errors) {
        Swal.fire({
          title: "Error!",
          text: `${error.response.data.errors}`,
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      }
      console.error("Error during POST request:", error);
    } finally {
      setIsSubmitted(false);
    }
  };

  useEffect(() => {
    fetchUpcomingServicesData();
  }, []);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      formData.uploadImage.forEach((file: any) =>
        URL.revokeObjectURL(file.preview)
      );
  });

  var Categorysettings = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {userType === "service_provider" ? (
        <React.Fragment>
          <Box sx={{ py: 2 }}>
            <Container>
              <Grid container spacing={3}>
                {providerMenu &&
                  providerMenu.map((item: any) => (
                    <Grid item xs={12} lg={3} key={item.id}>
                      <Card
                        sx={{
                          boxShadow: "0px 15px 40px 0px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <CardContent
                          sx={{
                            "&:last-child": { pb: 2 },
                            textDecoration: "none",
                            color: "#000",
                          }}
                          component={Link}
                          to={item.url}
                        >
                          <Stack
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                          >
                            <Avatar
                              variant="rounded"
                              src={item.image}
                              sx={{ width: "20%", height: "20%" }}
                            />
                            <Typography variant="h6">{item.title}</Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                {upcomingServices.length > 0 && (
                  <Grid item xs={12} lg={12}>
                    <Box>
                      <Typography variant="h3">Upcoming</Typography>
                      <Box
                        sx={{
                          maxWidth: "950px",
                          mt: 2,
                          "& .slick-slide": {
                            px: 1,
                          },
                        }}
                      >
                        <Slider {...Categorysettings}>
                          {upcomingServices &&
                            upcomingServices.map((item: any) => (
                              <Card
                                key={item.id}
                                sx={{
                                  boxShadow:
                                    "0px 15px 40px 0px rgba(0, 0, 0, 0.05)",
                                  border: "1px solid #ccc",
                                  borderRadius: "10px",
                                }}
                              >
                                <CardContent sx={{ "&:last-child": { pb: 2 } }}>
                                  <Stack
                                    direction="column"
                                    alignItems="left"
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{
                                      "& .MuiTypography-root": {
                                        fontSize: "12px",
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{ fontWeight: 700 }}
                                    >
                                      {item.service_name}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      component={Stack}
                                      direction="row"
                                      spacing={1}
                                    >
                                      <b>Name:</b> <span>{item.name} </span>
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      component={Stack}
                                      direction="row"
                                      spacing={1}
                                    >
                                      <b>Address:</b>{" "}
                                      <span>{item.address}</span>
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      component={Stack}
                                      direction="row"
                                      spacing={1}
                                    >
                                      <b>Date&Time:</b>{" "}
                                      <span>{item.service_date_time}</span>
                                    </Typography>

                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      alignItems="center"
                                      sx={{ marginTop: "20px !important" }}
                                    >
                                      <Button
                                        disabled={!!acceptedItems[item.id]}
                                        variant="outlined"
                                        onClick={() =>
                                          handleAcceptService(item.id)
                                        }
                                        sx={{ py: 0 }}
                                      >
                                        {acceptedItems[item.id] ? (
                                          <span style={{ color: "#837373" }}>
                                            Accepting...
                                          </span>
                                        ) : (
                                          "Accept"
                                        )}
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          handleRejectService(item.id)
                                        }
                                        sx={{
                                          "&.MuiButton-outlined": {
                                            backgroundColor: "red",
                                            borderColor: "red",
                                            py: 0,
                                            "&:hover": {
                                              backgroundColor: "#b23232f0",
                                              borderColor: "#b23232f0",
                                            },
                                          },
                                        }}
                                      >
                                        Reject
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </CardContent>
                              </Card>
                            ))}
                        </Slider>
                      </Box>
                    </Box>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  lg={12}
                  component={"form"}
                  onSubmit={handleClickSubmit}
                >
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{ textAlign: { xs: "center", lg: "start" } }}
                    >
                      Upload latest service photos
                    </Typography>
                    <Card
                      sx={{
                        boxShadow: "0px 15px 40px 0px rgba(0, 0, 0, 0.05)",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        mt: 2,
                      }}
                    >
                      <CardContent
                        sx={{
                          "&:last-child": {
                            pb: 2,
                          },
                        }}
                      >
                        <Box
                          {...getRootProps({ className: "dropzone" })}
                          sx={{ cursor: "pointer" }}
                        >
                          <input
                            type="file"
                            name="uploadImage"
                            {...getInputProps()}
                          />
                          <Box sx={{ textAlign: "center" }}>
                            <CloudUploadIcon sx={{ fontSize: "40px" }} />
                          </Box>
                          <Typography
                            variant="h6"
                            textAlign="center"
                            alignItems="center"
                            sx={{ margin: 0 }}
                          >
                            Drag 'n' drop some files here, or click to select
                            files
                          </Typography>

                          <Box sx={{ textAlign: "center", mt: 1 }}>
                            <Button variant="contained">Upload</Button>
                          </Box>
                          {error.uploadImage && (
                            <Typography color="error">
                              {error.uploadImage}
                            </Typography>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitted}
                    >
                      {isSubmitted ? (
                        <span style={{ color: "#fff" }}>Submitting...</span>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Drawer
            isOpen={rejectService}
            handleClose={() => handleRejectService(serviceBookingId)}
            title="Reject Service"
          >
            <RejectService
              handleClose={() => handleRejectService(serviceBookingId)}
              serviceBookingId={serviceBookingId}
              setUpcomingServices={setUpcomingServices}
            />
          </Drawer>
        </React.Fragment>
      ) : (
        <Box>Welcome Admin</Box>
      )}
    </>
  );
};

export default ServiceProviderDashboard;
