import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../../../component/input";
import Swal from "sweetalert2";
import Services from "../../../../api/services";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/system";
import { state } from "../../../../component/header/helper";

interface driverLicenseProps {
  id: number;
  front_image: any;
  back_image: any;
  province: string;
  customer_number: string;
  issue_date: string;
  expiry_date: string;
  allow_background_check: number;
}
interface props {
  handleClose: (value: boolean) => void;
}

const DriverLicense: React.FC<props> = ({ handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<driverLicenseProps>>({});
  const [formData, setFormData] = useState<driverLicenseProps>({
    id: 0,
    front_image: "",
    back_image: "",
    province: "",
    customer_number: "",
    issue_date: "",
    expiry_date: "",
    allow_background_check: 1,
  });

  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);

  const validateForm = () => {
    const newErrors: Partial<driverLicenseProps> = {};

    if (!formData.customer_number) {
      newErrors.customer_number = "Customer number is required";
    }

    if (!formData.issue_date) {
      newErrors.issue_date = "Issue date is required";
    }

    if (!formData.expiry_date) {
      newErrors.expiry_date = "Expiry date is required";
    }

    if (!formData.province) {
      newErrors.province = "Province is required";
    }

    if (!formData.front_image) {
      newErrors.front_image = "Driver License Front is required";
    }

    if (!formData.back_image) {
      newErrors.back_image = "Driver License Back is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement; // Explicit casting to HTMLInputElement
    if (files && files.length > 0) {
      // Handle file selection
      const selectedFile = files[0];
      setFormData((prevData) => ({ ...prevData, [name]: selectedFile }));
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          if (name === "front_image") {
            setFrontImage(event.target.result as string);
          } else {
            setBackImage(event.target.result as string);
          }
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Handle other input types
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const removeImage = (imageType: "front" | "back") => {
    if (imageType === "front") {
      setFrontImage(null);
      const frontInput = document.querySelector(
        'input[name="front_image"]'
      ) as HTMLInputElement;
      if (frontInput) {
        frontInput.value = ""; // Clear the input value
      }
    } else {
      setBackImage(null);
      const backInput = document.querySelector(
        'input[name="back_image"]'
      ) as HTMLInputElement;
      if (backInput) {
        backInput.value = ""; // Clear the input value
      }
    }
  };

  const handleSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      var data = new FormData();
      data.append("customer_number", formData.customer_number);
      data.append(
        "issue_date",
        new Date(formData.issue_date).toISOString().split("T")[0]
      );
      data.append(
        "expiry_date",
        new Date(formData.expiry_date).toISOString().split("T")[0]
      );
      data.append("province", formData.province);
      data.append("back_image", formData.back_image);
      data.append("front_image", formData.front_image);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateDrivingLicenseService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Driver license has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const fetchDriverLicense = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getDrivingLicenseService(
          options
        );

        setFormData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDriverLicense();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <FormGroup sx={{ mt: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Customer Number</FormLabel>
              <Input
                type="text"
                name="customer_number"
                value={formData.customer_number}
                onChange={handleInputChange}
              />

              {errors.customer_number && (
                <Typography color="error">{errors.customer_number}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Issue Date</FormLabel>
              <Input
                type="date"
                name="issue_date"
                value={
                  formData.issue_date
                    ? new Date(formData.issue_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleInputChange}
              />

              {errors.issue_date && (
                <Typography color="error">{errors.issue_date}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Expiry Date</FormLabel>
              <Input
                type="date"
                name="expiry_date"
                value={
                  formData.expiry_date
                    ? new Date(formData.expiry_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleInputChange}
              />

              {errors.expiry_date && (
                <Typography color="error">{errors.expiry_date}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Province</FormLabel>
              <Select
                fullWidth
                name="province"
                value={formData.province}
                displayEmpty
                defaultValue="Please select"
                label="province"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Please select
                </MenuItem>
                {state &&
                  state.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>

              {errors.province && (
                <Typography color="error">{errors.province}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup
              sx={{
                "& img": {
                  mt: 1,
                  maxHeight: "150px",
                  maxWidth: "150px",
                  objectFit: "cover",
                },
              }}
            >
              <FormLabel sx={{ mb: 1 }}>Upload photo Front</FormLabel>
              <Input
                type="file"
                name="front_image"
                // value={formData.front_image}
                onChange={handleInputChange}
              />

              {formData.front_image ? (
                <img
                  src={
                    formData.front_image
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${formData.front_image}`
                      : ""
                  }
                  alt={""}
                />
              ) : (
                ""
              )}

              {frontImage && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                    onClick={() => removeImage("front")}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={frontImage}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.front_image && (
                <Typography color="error">{errors.front_image}</Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormGroup
              sx={{
                "& img": {
                  mt: 1,
                  maxHeight: "150px",
                  maxWidth: "150px",
                  objectFit: "cover",
                },
              }}
            >
              <FormLabel sx={{ mb: 1 }}>Upload Photo Back</FormLabel>
              <Input
                type="file"
                name="back_image"
                // value={formData.back_image}
                onChange={handleInputChange}
              />

              {formData.back_image ? (
                <img
                  src={
                    formData.back_image
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${formData.back_image}`
                      : ""
                  }
                  alt={""}
                />
              ) : (
                ""
              )}

              {backImage && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => removeImage("back")}
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={backImage}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.back_image && (
                <Typography color="error">{errors.back_image}</Typography>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          sx={{ mt: 2 }}
        >
          {formData.id !== 0 &&
          (formData.allow_background_check === 1 ||
            formData.allow_background_check === 2) ? (
            <Typography variant="h6" sx={{ color: "red" }}>
              Note: Documents is under review yet
            </Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={
                (formData.id !== 0 &&
                  (formData.allow_background_check === 1 ||
                    formData.allow_background_check === 2)) ||
                isSubmitted
              }
            >
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default DriverLicense;
