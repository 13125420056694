import dictionary from "../dictionary";
import requestModule from "../methods";

const { provider } = dictionary;
const { post, get, put, deleteMethod } = requestModule;

const ProviderService = {
  getProviderDetailsService: (id: number, options: {}) => {
    return get(provider.getProviderDetails(id), options);
  },
  providerRegisterService: (body: {}) => {
    return post(provider.providerRegistration(), body);
  },
  bankDetailService: (body: {}, options: {}) => {
    return put(provider.updateBankDetail(), body, options);
  },
  getBankDetailService: (options: {}) => {
    return get(provider.getBankDetail(), options);
  },
  getCompanyRegService: (options: {}) => {
    return get(provider.getCompanyReg(), options);
  },
  updateCompanyRegService: (body: {}, options: {}) => {
    return put(provider.updateCompanyReg(), body, options);
  },
  getDrivingLicenseService: (options: {}) => {
    return get(provider.getDrivingLicense(), options);
  },
  updateDrivingLicenseService: (body: {}, options: {}) => {
    return put(provider.updateDrivingLicense(), body, options);
  },
  getAgreementDetailsService: (options: {}) => {
    return get(provider.getAgreementDetails(), options);
  },
  updateAgreementDetailsService: (body: {}, options: {}) => {
    return put(provider.updateAgreementDetails(), body, options);
  },
  getWorkEligibilityService: (options: {}) => {
    return get(provider.getWorkEligibility(), options);
  },
  updateWorkEligibilityService: (body: {}, options: {}) => {
    return put(provider.updateWorkEligibility(), body, options);
  },
  getSINNumberService: (options: {}) => {
    return get(provider.getSINNumber(), options);
  },
  updateSINNumberService: (body: {}, options: {}) => {
    return put(provider.updateSINNumber(), body, options);
  },
  getAvailabilityService: (options: {}) => {
    return get(provider.getAvailability(), options);
  },
  updateAvailabilityService: (id: number, body: {}, options: {}) => {
    return put(provider.updateAvailability(id), body, options);
  },
  getServicesData: (options: {}) => {
    return get(provider.getServices(), options);
  },
  addServiceData: (body: {}, options: {}) => {
    return post(provider.addService(), body, options);
  },
  viewServiceDataById: (id: number, options: {}) => {
    return get(provider.viewService(id), options);
  },
  updateServiceDataById: (id: number, body: {}, options: {}) => {
    return put(provider.updateService(id), body, options);
  },
  deleteServiceDataById: (id: number, options: {}) => {
    return deleteMethod(provider.deleteService(id), options);
  },
  getUpcomingServicesData: (options: {}) => {
    return get(provider.getUpcomingServices(), options);
  },
  updateUpcomingServicesData: (id: number, body: {}, options: {}) => {
    return put(provider.updateUpcomingServices(id), body, options);
  },
  getServiceHistoryData: (options: {}) => {
    return get(provider.getServiceHistory(), options);
  },
  postCompleteServiceData: (body: {}, options: {}) => {
    return post(provider.postCompleteService(), body, options);
  },
  getEarningData: (sortBy: any, options: {}) => {
    return get(provider.getEarning(sortBy), options);
  },
  getReviewsData: (options: {}) => {
    return get(provider.getReviews(), options);
  },
  postSliderImageService: (body: {}, options: {}) => {
    return post(provider.postSliderImage(), body, options);
  },
  updateStripeStatusService: (body: {}, options: {}) => {
    return post(provider.updateStripeStatus(), body, options);
  },
};

export default ProviderService;
