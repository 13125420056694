import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Input from "../../../component/input";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { state } from "../../../component/header/helper";
import Swal from "sweetalert2";
import Services from "../../../api/services";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../store/reducers";

interface profileEditProps {
  fname: string;
  lname: string;
  photo: any;
  email: string;
  mobile: string;
  city: string;
  line1: string;
  line2: string;
  province: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
}

interface userDetailsProps {
  userDetails: any;
  handleClose: (value: boolean) => void;
}

const ProfileEdit: React.FC<userDetailsProps> = ({
  userDetails,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<profileEditProps>>({});
  const [formData, setFormData] = useState<profileEditProps>({
    fname: userDetails.first_name,
    lname: userDetails.last_name,
    photo: null,
    email: userDetails.email,
    mobile: `${userDetails.phone_extension}${userDetails.phone_number}`,
    city: userDetails.city,
    line1: userDetails.landmark_1,
    line2: userDetails.landmark_2,
    postalCode: userDetails.pincode,
    province: userDetails.state,
    countryCode: userDetails.phone_extension,
    phoneNumber: userDetails.phone_number,
  });

  const validateForm = () => {
    const newErrors: Partial<profileEditProps> = {};
    if (!formData.fname.trim()) {
      newErrors.fname = "First name is required";
    }

    if (!formData.lname.trim()) {
      newErrors.lname = "Last name is required";
    }

    // if (!formData.photo.trim()) {
    //   newErrors.photo = "Photo is required";
    // }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile is required";
    } else if (formData.mobile.length < 11) {
      newErrors.mobile = "mobile number must be valid";
    }

    if (!formData.line1.trim()) {
      newErrors.line1 = "Building Apartment No is required";
    }

    if (!formData.line2.trim()) {
      newErrors.line2 = "Street Name/No is required";
    }

    if (!formData.city.trim()) {
      newErrors.city = "City is required";
    }

    // Validate postal code (6 alphanumeric characters)
    const postalCodeRegex = /^[A-Za-z0-9]{6}$/;
    if (!formData.postalCode.trim()) {
      newErrors.postalCode = "Postal code is required";
    } else if (!postalCodeRegex.test(formData.postalCode)) {
      newErrors.postalCode =
        "Postal code must be exactly 6 alphanumeric characters";
    }

    if (!formData.province.trim()) {
      newErrors.province = "Province is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement; // Explicit casting to HTMLInputElement
    if (files && files.length > 0) {
      // Handle file selection
      const selectedFile = files[0];
      setFormData((prevData) => ({ ...prevData, [name]: selectedFile }));
    } else {
      // Handle other input types
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handlePhoneChange = (
    value: string,
    country: string,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setFormData((prevData) => ({ ...prevData, mobile: formattedValue }));

    const phoneNumberArray = formattedValue.split(" ");
    const countryCode = phoneNumberArray[0];
    const phoneNum = phoneNumberArray.slice(1).join(" ");
    const phoneNumber = phoneNum.replace(/[^\d]/g, "");

    setFormData((prevData) => ({ ...prevData, countryCode, phoneNumber }));
    setErrors((prevErrors) => ({ ...prevErrors, mobile: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      var bodyFormData = new FormData();
      bodyFormData.append("first_name", formData.fname);
      bodyFormData.append("last_name", formData.lname);
      // bodyFormData.append("email", formData.email);
      bodyFormData.append("phone_extension", formData.countryCode);
      bodyFormData.append("phone_number", formData.phoneNumber);
      bodyFormData.append("landmark_1", formData.line1);
      bodyFormData.append("landmark_2", formData.line2);
      bodyFormData.append("city", formData.city);
      bodyFormData.append("pincode", formData.postalCode);
      bodyFormData.append("state", formData.province);
      bodyFormData.append("profile_image", formData.photo);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await Services.auth.updateUserService(
            bodyFormData,
            options
          );
          if (response.status === 200) {
            dispatch(setUserData(response.data.userData));
            Swal.fire({
              title: "Thank You!",
              text: "Profile updated successfully",
              icon: "success",
              confirmButtonColor: "#11b4f5",
            });
          }
          handleClose(false);
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.message}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>First Name</FormLabel>
              <Input
                name="fname"
                placeholder="First name"
                type="text"
                value={formData.fname}
                onChange={handleInputChange}
              />
              {errors.fname && (
                <Typography color="error">{errors.fname}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Last Name</FormLabel>
              <Input
                name="lname"
                placeholder="Last name"
                type="text"
                value={formData.lname}
                onChange={handleInputChange}
              />
              {errors.lname && (
                <Typography color="error">{errors.lname}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Upload Photo</FormLabel>
              <Input name="photo" type="file" onChange={handleInputChange} />
              {/* {errors.photo && (
                <Typography color="error">{errors.photo}</Typography>
              )} */}
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Phone Number</FormLabel>
              <PhoneInput
                country={"us"}
                value={formData.mobile}
                onChange={(value, country, event, formattedValue) => {
                  if (typeof value === "string" || value === undefined) {
                    handlePhoneChange(
                      typeof value === "string" ? value : "",
                      typeof country === "string" ? country : "",
                      event,
                      formattedValue
                    );
                  }
                }}
              />
              {errors.mobile && (
                <Typography color="error">{errors.mobile}</Typography>
              )}
            </FormGroup>
          </Grid>
          {/* <Grid item xs={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Email Address</FormLabel>
              <Input
                name="email"
                placeholder="Email Address"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                readOnly={true}
              />
              {/* {errors.email && (
                <Typography color="error">{errors.email}</Typography>
              )} 
            </FormGroup>
          </Grid> */}

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
              <Input
                type="text"
                name="line1"
                placeholder="Building Apartment No"
                value={formData.line1}
                onChange={handleInputChange}
              />
              {errors.line1 && (
                <Typography color="error">{errors.line1}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <Input
                type="text"
                name="line2"
                placeholder="Street Name / No"
                value={formData.line2}
                onChange={handleInputChange}
              />
              {errors.line2 && (
                <Typography color="error">{errors.line2}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <Input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleInputChange}
              />
              {errors.city && (
                <Typography color="error">{errors.city}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <Input
                type="text"
                name="postalCode"
                placeholder="Postal Code"
                value={formData.postalCode}
                onChange={handleInputChange}
              />
              {errors.postalCode && (
                <Typography color="error">{errors.postalCode}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <Select
                fullWidth
                name="province"
                value={formData.province}
                displayEmpty
                defaultValue="Please select"
                label="province"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Please select
                </MenuItem>
                {state &&
                  state.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
              {errors.province && (
                <Typography color="error">{errors.province}</Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit" disabled={isSubmitted}>
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Update"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ProfileEdit;
