import { Outlet } from "react-router-dom";
import DashboardRoute from "../pages/dashboard/routes";
import Header from "../component/header";
import Footer from "../component/footer";
import { lazy } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const Details = lazy(() => import("../pages/details"));
const Home = lazy(() => import("../pages/home"));
const About = lazy(() => import("../pages/about"));
const Contact = lazy(() => import("../pages/contact"));
const CheckAvailablity = lazy(
  () => import("../pages/details/checkAvailablity")
);
const Login = lazy(() => import("../pages/auth/login"));
const SignUp = lazy(() => import("../pages/auth/sign-up"));
const ForgotPassword = lazy(() => import("../pages/auth/forgot-password"));
const ResetPassword = lazy(() => import("../pages/auth/reset-password"));
const Quickquote = lazy(() => import("../pages/quick-quote"));
const Services = lazy(() => import("../pages/services"));
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"));
const TermsAndCondition = lazy(() => import("../pages/term-and-conditions"));
const Booking = lazy(() => import("../pages/booking-service"));
const ComingSoon = lazy(() => import("../pages/coming-soon"));
const SuccessPayment = lazy(() => import("../pages/success-payment"));
const CancelPayment = lazy(() => import("../pages/cancel-payment"));
const BecomeHelpingHand = lazy(() => import("../pages/become-helping-hand"));

// const stripePromise = loadStripe(
//   "pk_test_51OSq2ZB19zrjqr0QoKc3DkYiDjAZeUU8nWiZIeeVQH3sB59OMIix0hxO2BY2JgHpfewkAixHohsa26cP71pldhXY006qUHaqoo"
// );   //test

const stripePromise = loadStripe(
"pk_live_51OSq2ZB19zrjqr0QHomWap2pFfk7Wol9kuH2pQObdHCekvwwdpchtFjMwIRQBApAIH2NWhV3qxXGpJCaS384Px3V00577VeWsc")

const MainRoute = [
  {
    path: "/",
    element: (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/details/:id",
        element: <Details />,
      },
      {
        path: "/check-availablity/:id/:providerId",
        element: <CheckAvailablity />,
      },
      {
        path: "/booking-a-service",

        element: (
          <Elements stripe={stripePromise}>
            <Booking />
          </Elements>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/sign-up",
        element: <SignUp />,
      },
      {
        path: "/become-helping-hand",
        element: <BecomeHelpingHand />,
      },
      {
        path: "/quick-quote",
        element: <Quickquote />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/term-and-conditions",
        element: <TermsAndCondition />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/coming-soon",
        element: <ComingSoon />,
      },
      {
        path: "/success/:id",
        element: <SuccessPayment />,
      },
      {
        path: "/cancel/:id",
        element: <CancelPayment />,
      },
    ],
  },

  {
    path: "/dashboard",
    element: <Outlet />,
    children: DashboardRoute,
  },
];

export default MainRoute;
