import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface SnowRemovalProps {
  formik: any;
}

const SnowRemovalCommercial: React.FC<SnowRemovalProps> = ({ formik }) => {
  const handleIconClick = () => {
    formik.setFieldValue("snowArea", [...formik.values.snowArea, ""]);
    formik.setFieldValue("snowAreaPrice", [...formik.values.snowAreaPrice, ""]);
  };

  const handleRemoveClick = (id: number) => {
    const newNoOfCarsDriveWay = [...formik.values.snowArea];
    newNoOfCarsDriveWay.splice(id, 1);
    const newNoOfCrDriveWayPrice = [...formik.values.snowAreaPrice];
    newNoOfCrDriveWayPrice.splice(id, 1);
    formik.setFieldValue("snowArea", newNoOfCarsDriveWay);
    formik.setFieldValue("snowAreaPrice", newNoOfCrDriveWayPrice);
  };

  const handlePriceChange = (itemId: number, newValue: any) => {
    const newPrices = [...formik.values.snowAreaPrice];
    newPrices[itemId] = newValue;
    formik.setFieldValue("snowAreaPrice", newPrices);
  };

  const handleNoOfCarsDriveWayChange = (itemId: number, newValue: any) => {
    const newNoOfCarsDriveWay = [...formik.values.snowArea];
    newNoOfCarsDriveWay[itemId] = newValue;
    formik.setFieldValue("snowArea", newNoOfCarsDriveWay);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        {Array.isArray(formik.values.snowArea) &&
          formik.values.snowArea.map((item: any, index: number) => (
            <React.Fragment key={item.id}>
              <Grid item xs={6} sm={6} lg={6}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Typography component={"span"}>Snow Removal Area</Typography>
                  <Box>
                    <Select
                      size="small"
                      name="snowArea"
                      value={formik.values.snowArea[index]}
                      onChange={(e) =>
                        handleNoOfCarsDriveWayChange(index, e.target.value)
                      }
                      sx={{ width: 150, height: "48px" }}
                      displayEmpty
                      defaultValue=""
                    >
                      <MenuItem disabled value="">
                        Please select
                      </MenuItem>
                      <MenuItem value="small">Small</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="large">Large</MenuItem>
                      <MenuItem value="very large">Very Large</MenuItem>
                    </Select>
                    {formik.touched.snowArea && formik.errors.snowArea && (
                      <Typography className="error">
                        {formik.errors.snowArea}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                  <Typography component={"span"}>$</Typography>
                  <Box>
                    <Input
                      type="text"
                      name="snowAreaPrice"
                      value={formik.values.snowAreaPrice[index]}
                      onChange={(e: any) =>
                        handlePriceChange(index, e.target.value)
                      }
                    />
                    {formik.touched.snowAreaPrice &&
                      formik.errors.snowAreaPrice && (
                        <Typography className="error">
                          {formik.errors.snowAreaPrice}
                        </Typography>
                      )}
                  </Box>
                  {index === 0 ? (
                    <IconButton
                      onClick={
                        formik.values.snowArea.length < 4
                          ? handleIconClick
                          : undefined
                      }
                      sx={{
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "#11b4f5",
                          color: "#FFF",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleRemoveClick(index)}
                      sx={{
                        backgroundColor: "red",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "red",
                          color: "#FFF",
                        },
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </React.Fragment>
          ))}

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Black Ice</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="commercialBlackIcePrice"
                value={formik.values.commercialBlackIcePrice}
                onChange={formik.handleChange}
              />
              {formik.touched.commercialBlackIcePrice &&
                formik.errors.commercialBlackIcePrice && (
                  <Typography className="error">
                    {formik.errors.commercialBlackIcePrice}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Snow more than 8 inches</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="commercialSnowSizePrice"
                value={formik.values.commercialSnowSizePrice}
                onChange={formik.handleChange}
              />
              {formik.touched.commercialSnowSizePrice &&
                formik.errors.commercialSnowSizePrice && (
                  <Typography className="error">
                    {formik.errors.commercialSnowSizePrice}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SnowRemovalCommercial;
