const ProviderDictionary = {
  getProviderDetails: (id: number) => `/register/get_provider_details/${id}`,
  providerRegistration: () => "/register/service_provider",
  getBankDetail: () => "/serviceprovider/get_bank_details",
  updateBankDetail: () => "/serviceprovider/add_or_update_bank_details",
  getCompanyReg: () => "/serviceprovider/get_company_regist",
  updateCompanyReg: () => "/serviceprovider/update_company_regist",
  getDrivingLicense: () => "/serviceprovider/get_driving_license",
  updateDrivingLicense: () => "/serviceprovider/update_driving_license",
  getAgreementDetails: () => "/serviceprovider/get_agreement_status",
  updateAgreementDetails: () => "/serviceprovider/update_agreement_status",
  getWorkEligibility: () => "/serviceprovider/get_work_eligible",
  updateWorkEligibility: () => "/serviceprovider/update_work_eligible",
  getSINNumber: () => "/serviceprovider/get_sin",
  updateSINNumber: () => "/serviceprovider/update_sin",
  getAvailability: () => "/serviceprovider/get_availability",
  updateAvailability: (id: number) =>
    `/serviceprovider/update_availability/${id}`,
  getServices: () => "/serviceprovider/get_all_services_list",
  addService: () => "/serviceprovider/post_services",
  viewService: (id: number) => `/serviceprovider/get_service_list/${id}`,
  updateService: (id: number) => `/serviceprovider/update_service_list/${id}`,
  deleteService: (id: number) => `/serviceprovider/delete_service_list/${id}`,
  getUpcomingServices: () => "/serviceprovider/get_all_bookings_for_provider",
  updateUpcomingServices: (id: number) =>
    `/serviceprovider/update_booked_service/${id}`,
  getServiceHistory: () => "/serviceprovider/get_services_history",
  postCompleteService: () => "/serviceprovider/post_complete_services",
  getEarning: (sortBy: any) => `/serviceprovider/get_earnings/${sortBy}`,
  getReviews: () => "/serviceprovider/get_reviews",
  postSliderImage: () => "/slidingimages/post_slide_images",
  updateStripeStatus: () => "/slidingimages/update_stripe_status",
};

export default ProviderDictionary;
