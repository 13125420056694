import { Button, FormGroup, FormLabel, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Input from "../../../../component/input";
import Swal from "sweetalert2";
import Services from "../../../../api/services";

interface FormDataProps {
  first_name: string;
  last_name: string;
  account_number: string;
  transit_number: string;
  institution_number: string;
  bank_name: string;
}

const BankingDeatils: React.FC = () => {
  const [errors, setErrors] = useState<Partial<FormDataProps>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>({
    first_name: "",
    last_name: "",
    account_number: "",
    transit_number: "",
    institution_number: "",
    bank_name: "",
  });

  const validateForm = () => {
    const newErrors: Partial<FormDataProps> = {};

    if (!formData.first_name.trim()) {
      newErrors.first_name = "First name is required";
    }

    if (!formData.last_name.trim()) {
      newErrors.last_name = "Last name is required";
    }

    // Validate account number (should be exactly 12 digits)
    if (
      !formData.account_number ||
      formData.account_number.length !== 12 ||
      isNaN(Number(formData.account_number))
    ) {
      newErrors.account_number = "Account No must be exactly 12 digits";
    }

    // Validate transit number (should be exactly 5 digits)
    if (
      !formData.transit_number ||
      formData.transit_number.length !== 5 ||
      isNaN(Number(formData.transit_number))
    ) {
      newErrors.transit_number = "Transit No must be exactly 5 digits";
    }

    // Validate institution number (should be exactly 3 digits)
    if (
      !formData.institution_number ||
      formData.institution_number.length !== 3 ||
      isNaN(Number(formData.institution_number))
    ) {
      newErrors.institution_number = "Institution No must be exactly 3 digits";
    }

    if (!formData.bank_name.trim()) {
      newErrors.bank_name = "Bank Name is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      const data = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        account_number: formData.account_number,
        transit_number: formData.transit_number,
        institution_number: formData.institution_number,
        bank_name: formData.bank_name,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.bankDetailService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Updated!",
              text: "Bank detail has been Updated.",
              icon: "success",
            });
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await Services.provider.getBankDetailService(
            options
          );
          setFormData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataFromApi();
  }, []);

  return (
    <Box sx={{ py: 2 }}>
      <Typography sx={{ fontSize: "24px", mb: 2, fontWeight: 600 }}>
        Banking Details
      </Typography>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>First Name</FormLabel>
                <Input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                {errors.first_name && (
                  <Typography color="error">{errors.first_name}</Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Last Name</FormLabel>
                <Input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
                {errors.last_name && (
                  <Typography color="error">{errors.last_name}</Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Account No</FormLabel>
                <Input
                  type="number"
                  name="account_number"
                  placeholder="Account No"
                  value={formData.account_number}
                  onChange={handleInputChange}
                />
                {errors.account_number && (
                  <Typography color="error">{errors.account_number}</Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Transit No</FormLabel>
                <Input
                  type="number"
                  name="transit_number"
                  placeholder="Transit No"
                  value={formData.transit_number}
                  onChange={handleInputChange}
                />
                {errors.transit_number && (
                  <Typography color="error">{errors.transit_number}</Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Institution No</FormLabel>
                <Input
                  type="number"
                  name="institution_number"
                  placeholder="Institution No"
                  value={formData.institution_number}
                  onChange={handleInputChange}
                />
                {errors.institution_number && (
                  <Typography color="error">
                    {errors.institution_number}
                  </Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Bank Name</FormLabel>
                <Input
                  type="text"
                  name="bank_name"
                  placeholder="Bank Name"
                  value={formData.bank_name}
                  onChange={handleInputChange}
                />
                {errors.bank_name && (
                  <Typography color="error">{errors.bank_name}</Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitted}
                sx={{
                  "&.MuiButtonBase-root": {
                    borderRadius: "30px",
                    height: "48px",
                    mt: 1,
                  },
                }}
              >
                {isSubmitted ? (
                  <span style={{ color: "#fff" }}>Submitting...</span>
                ) : (
                  "Save"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default BankingDeatils;
