import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import Services from "../../../../../api/services";

interface ProviderDetails {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_extension: string;
  landmark_1: string;
  landmark_2: string;
  city: string;
  pincode: string;
  province: string;
  company_website: string;
  legally_authorized_to_work_in_canada: number;
  status: string;
  services: string;
  customer_number: string;
  issue_date: string;
  expiry_date: string;
  front_image: string;
  back_image: string;
  work_doc_front: string;
  work_doc_back: string;
  document_type: string;
  sin_number: string;
  terms_conditions: number;
  company_name: string;
  established_year: string;
  registration_number: string;
  state: string;
}
interface providerEditProps {
  id: number;
  userStatus: string;
  backgroundCheck: string;
}

interface userDetailsProps {
  userData: any;
  setProviderData: (value: any) => void;
  handleClose: (value: boolean) => void;
}

const ProviderEdit: React.FC<userDetailsProps> = ({
  userData,
  setProviderData,
  handleClose,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [providerDetails, setProviderDetails] = useState<ProviderDetails[]>([]);
  const [errors, setErrors] = useState<Partial<providerEditProps>>({});
  const [formData, setFormData] = useState<providerEditProps>({
    id: userData.id,
    userStatus: userData.userStatus,
    backgroundCheck: userData.backgroundCheck,
  });

  const validateForm = () => {
    const newErrors: Partial<providerEditProps> = {};
    if (!formData.userStatus.trim()) {
      newErrors.userStatus = "User status is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      const bodyFormData = {
        id: formData.id,
        user_status: formData.userStatus,
        allow_background_check: formData.backgroundCheck,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await Services.admin.updateUserStatusService(
            bodyFormData,
            options
          );
          if (response.status === 200) {
            const providerResponse =
              await Services.admin.getProviderListService(options);
            setProviderData(providerResponse.data.data);
            Swal.fire({
              title: "Thank You!",
              text: "Status updated successfully",
              icon: "success",
              confirmButtonColor: "#11b4f5",
            });
          }
          handleClose(false);
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.message}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.getProviderDetailsService(
            userData.id,
            options
          );
          setProviderDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProviderData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>First Name</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].first_name}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Last Name</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].last_name}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].email}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Phone Number</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                  `${providerDetails[0].phone_extension} ${providerDetails[0].phone_number}`}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>Address</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].landmark_1}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].landmark_2}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>City</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].city}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Postal Code</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].pincode}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>Province</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].state}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>Company's Website</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                  providerDetails[0].company_website}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>
                Are you legally authorized to work in canada?
              </FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                providerDetails[0].legally_authorized_to_work_in_canada === 1
                  ? "Yes"
                  : "No"}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>What is your present status?</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].status}{" "}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>What services are you willing to offer?</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].services}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant="h6">Documents</Typography>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant="h6">1. Driver License</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Customer Number</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                  providerDetails[0].customer_number}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Issue Date</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].issue_date}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Expiry Date</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].expiry_date}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Province</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].province}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Upload photo Front</FormLabel>
              <Box
                className={""}
                sx={{
                  display: "flex !important",
                  alignItems: "center",
                  mt: 1,
                  "& img": {
                    height: "150px",
                    width: "150px",
                    objectFit: "cover",
                  },
                }}
              >
                <img
                  src={
                    providerDetails.length > 0 && providerDetails[0].front_image
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${providerDetails[0].front_image}`
                      : ""
                  }
                  alt={""}
                />
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Upload Photo Back</FormLabel>
              <Box
                className={""}
                sx={{
                  display: "flex !important",
                  alignItems: "center",
                  mt: 1,
                  "& img": {
                    height: "150px",
                    width: "150px",
                    objectFit: "cover",
                  },
                }}
              >
                <img
                  src={
                    providerDetails.length > 0 && providerDetails[0].back_image
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${providerDetails[0].back_image}`
                      : ""
                  }
                  alt={""}
                />
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant="h6">2. Proof Of Work Eligibility</Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Upload photo Front</FormLabel>
              <Box
                className={""}
                sx={{
                  display: "flex !important",
                  alignItems: "center",
                  mt: 1,
                  "& img": {
                    height: "150px",
                    width: "150px",
                    objectFit: "cover",
                  },
                }}
              >
                <img
                  src={
                    providerDetails.length > 0 &&
                    providerDetails[0].work_doc_front
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${providerDetails[0].work_doc_front}`
                      : ""
                  }
                  alt={""}
                />
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Upload Photo Back</FormLabel>
              <Box
                className={""}
                sx={{
                  display: "flex !important",
                  alignItems: "center",
                  mt: 1,
                  "& img": {
                    height: "150px",
                    width: "150px",
                    objectFit: "cover",
                  },
                }}
              >
                <img
                  src={
                    providerDetails.length > 0 &&
                    providerDetails[0].work_doc_back
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${providerDetails[0].work_doc_back}`
                      : ""
                  }
                  alt={""}
                />
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>What document are you providing</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].document_type}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant="h6">3. SIN Number</Typography>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>SIN Number</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].sin_number}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant="h6">4. Service Providers Agreement</Typography>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>Service Providers Agreement</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                providerDetails[0].terms_conditions === 1
                  ? "Yes"
                  : "No"}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography variant="h6">5. Company Registration</Typography>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>Company Name</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 && providerDetails[0].company_name}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Established Year</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                  providerDetails[0].established_year}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Registration Number</FormLabel>
              <Box
                className={"form-control"}
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                {providerDetails.length > 0 &&
                  providerDetails[0].registration_number}
              </Box>
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>Background Check</FormLabel>
              <Select
                fullWidth
                name="backgroundCheck"
                value={formData.backgroundCheck}
                displayEmpty
                defaultValue="Please select"
                label="backgroundCheck"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Please select
                </MenuItem>
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Done</MenuItem>
                <MenuItem value={3}>Reject</MenuItem>
              </Select>
              {errors.backgroundCheck && (
                <Typography color="error">{errors.backgroundCheck}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormGroup>
              <FormLabel>User Status</FormLabel>
              <Select
                fullWidth
                name="userStatus"
                value={formData.userStatus}
                displayEmpty
                defaultValue="Please select"
                label="userStatus"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Please select
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approve">Approve</MenuItem>
                <MenuItem value="reject">Reject</MenuItem>
              </Select>
              {errors.userStatus && (
                <Typography color="error">{errors.userStatus}</Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit" disabled={isSubmitted}>
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Update"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ProviderEdit;
