import { Button, FormGroup, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../../../component/input";
import Services from "../../../../api/services";
import Swal from "sweetalert2";
import { Stack } from "@mui/system";

interface CompanyRegistrationProps {
  id: number;
  company_name: string;
  established_year: string;
  registration_number: string;
  allow_background_check: number;
}

interface companyProps {
  handleClose: (value: boolean) => void;
}

const CompanyRegistration: React.FC<companyProps> = ({ handleClose }) => {
  const [errors, setErrors] = useState<Partial<CompanyRegistrationProps>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<CompanyRegistrationProps>({
    id: 0,
    company_name: "",
    established_year: "",
    registration_number: "",
    allow_background_check: 1,
  });

  const validateForm = () => {
    const newErrors: Partial<CompanyRegistrationProps> = {};

    if (!formData.company_name.trim()) {
      newErrors.company_name = "Company name is required";
    }

    // Established Year Validation
    const currentYear = new Date().getFullYear();
    if (!formData.established_year) {
      newErrors.established_year = "Established Year is required";
    } else if (!/^\d{4}$/.test(formData.established_year)) {
      newErrors.established_year =
        "Established Year must be a valid 4-digit year";
    } else if (
      parseInt(formData.established_year) < 1900 ||
      parseInt(formData.established_year) > currentYear
    ) {
      newErrors.established_year = `Established Year must be between 1900 and ${currentYear}`;
    }

    if (!formData.registration_number.trim()) {
      newErrors.registration_number = "Registration Number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitted(true);
      const data = {
        company_name: formData.company_name,
        established_year: formData.established_year,
        registration_number: formData.registration_number,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateCompanyRegService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Company registration has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const fetchCompanyRegistration = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getCompanyRegService(options);

        setFormData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCompanyRegistration();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>Company Name</FormLabel>
          <Input
            type="text"
            name="company_name"
            placeholder="Company Name"
            value={formData.company_name}
            onChange={handleInputChange}
          />
          {errors.company_name && (
            <Typography color="error">{errors.company_name}</Typography>
          )}
        </FormGroup>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>Established Year</FormLabel>
          <Input
            type="number"
            name="established_year"
            placeholder="YYYY"
            min="1900"
            max={new Date().getFullYear()}
            value={formData.established_year}
            onChange={handleInputChange}
          />
          {errors.established_year && (
            <Typography color="error">{errors.established_year}</Typography>
          )}
        </FormGroup>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>Registration Number</FormLabel>
          <Input
            type="text"
            name="registration_number"
            placeholder="Registration Number"
            value={formData.registration_number}
            onChange={handleInputChange}
          />
          {errors.registration_number && (
            <Typography color="error">{errors.registration_number}</Typography>
          )}
        </FormGroup>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {formData.id !== 0 &&
          (formData.allow_background_check === 1 ||
            formData.allow_background_check === 2) ? (
            <Typography variant="h6" sx={{ mt: 2, color: "red" }}>
              Note: Documents is under review yet
            </Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={
                (formData.id !== 0 &&
                  (formData.allow_background_check === 1 ||
                    formData.allow_background_check === 2)) ||
                isSubmitted
              }
            >
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default CompanyRegistration;
