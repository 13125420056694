import {
  Typography,
  Box,
  Grid,
  Button,
  styled,
  // Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { IMAGES } from "../../../assets/images";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Drawer from "../component/Drawer";
import CancelService from "./cancel-service";
import Services from "../../../api/services";
import WithdrawService from "./withdraw-service";

const Liststyled = styled("div")({
  "& .MuiTypography-h5": {
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "uppercase",
  },
  "& .MuiTypography-caption": {
    color: "#74777c",
  },
  "& .MuiRating-root": {
    fontSize: "16px",
  },
  "& .MuiTypography-body1": {
    fontWeight: 400,
    fontSize: "12px",
  },
});

const MyServices: React.FC = () => {
  const [serviceData, setServiceData] = useState([]);
  const [serviceBookingId, setServiceBookingId] = useState(0);
  const [cancelService, setCancelService] = useState(false);
  const [withdrawService, setWithdrawService] = useState(false);
  const [showCancelMessage, setShowCancelMessage] = useState(false);

  const handleCancelService = (bookingId: number) => {
    setServiceBookingId(bookingId);
    setCancelService(!cancelService);
  };

  const handleCancelClick = (canCancelService: boolean, booking_id: number) => {
    if (canCancelService) {
      handleCancelService(booking_id); // Call your cancel service function
    } else {
      setShowCancelMessage(true); // Show the message if cancel is not allowed
    }
  };

  const handleWithdrawClick = (booking_id: number) => {
    setServiceBookingId(booking_id);
    setWithdrawService(!withdrawService);
  };

  const handleClosePopup = () => {
    setShowCancelMessage(false); // Close popup when user clicks "OK"
  };

  const fetchServiceData = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.customer.getMyServiceData(options);
        setServiceData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchServiceData();
  }, []);

  const checkServiceTime = (createdAt: any) => {
    const currentTime = new Date();
    const createdTime = new Date(createdAt);
    const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
    return (
      currentTime.getTime() - createdTime.getTime() < twelveHoursInMilliseconds
    );
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case "pending":
        return "Withdraw Service";
      case "accepted":
        return "Cancel Service";
      case "rejected":
        return "Rejected";
      case "issue":
        return "Issue";
      case "completed":
        return "Book Again";
      case "cancelled":
        return "Cancelled";
      case "withdraw":
        return "Withdraw";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ fontSize: "18px" }}>
          My Services
        </Typography>
        {serviceData.map((item: any) => {
          const canCancelService =
            item.status === "accepted" && checkServiceTime(item.created_at);

          return (
            <React.Fragment key={item.id}>
              <Box sx={{ py: 2, borderBottom: "1px solid #ccc" }}>
                <Grid container spacing={2} justifyContent={"space-between"}>
                  <Grid item xs={12} lg={9}>
                    <Stack direction={"row"} spacing={2}>
                      <Box
                        sx={{
                          "& img": {
                            width: "50px",
                            borderRadius: "5px",
                            "@media(max-width:768px)": { marginTop: "7px" },
                          },
                        }}
                      >
                        <img
                          style={{ height: "63px" }}
                          src={
                            item.profile_image
                              ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${item.profile_image}`
                              : IMAGES.Team1
                          }
                          alt=""
                        />
                      </Box>
                      <Liststyled sx={{ flex: 1 }}>
                        <Typography variant="h5">{item.name}</Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600 !important",
                          }}
                        >
                          {item.service_name} - ({item.service_date_time})
                        </Typography>
                        <Typography variant="body1">
                          {item.description}
                        </Typography>
                      </Liststyled>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
                      <Button
                        disabled={
                          !(
                            item.status === "completed" ||
                            item.status === "accepted" ||
                            item.status === "pending"
                          )
                        }
                        onClick={() => {
                          if (item.status === "pending") {
                            handleWithdrawClick(item.booking_id);
                          } else if (item.status === "accepted") {
                            handleCancelClick(
                              canCancelService,
                              item.booking_id
                            );
                          }
                        }}
                        variant="contained"
                        component={Link}
                        to={
                          item.status !== "completed"
                            ? ""
                            : `/details/${item.service_id}`
                        }
                        sx={{
                          "&.MuiButton-contained": {
                            background:
                              item.status === "completed"
                                ? "#11b4f5"
                                : item.status === "accepted" ||
                                  item.status === "pending"
                                ? "#ef5350"
                                : "#CCCCCC",
                            borderColor:
                              item.status === "completed"
                                ? "#11b4f5"
                                : item.status === "accepted" ||
                                  item.status === "pending"
                                ? "#ef5350"
                                : "#CCCCCC",
                            textTransform: "uppercase",
                            "&.Mui-disabled": {
                              color: "#fff !important",
                              background: "#CCC !important",
                            },
                            "&:hover": {
                              background:
                                item.status === "completed"
                                  ? "#11b4f5"
                                  : item.status === "accepted" ||
                                    item.status === "pending"
                                  ? "#ef5350"
                                  : "#CCCCCC",
                              borderColor:
                                item.status === "completed"
                                  ? "#11b4f5"
                                  : item.status === "accepted" ||
                                    item.status === "pending"
                                  ? "#ef5350"
                                  : "#CCCCCC",
                            },
                          },
                        }}
                      >
                        {getStatusLabel(item.status)}
                      </Button>
                      {/* {showCancelMessage && !canCancelService && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{ mt: 1 }}
                        >
                          The cancellation time frame has expired.
                        </Typography>
                      )} */}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          );
        })}
        {/* <Box
          sx={{
            textAlign: "center",
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination count={5} variant="outlined" shape="rounded" />
        </Box> */}
      </Box>
      <Drawer
        isOpen={cancelService}
        handleClose={() => handleCancelService(serviceBookingId)}
        title="Cancel Service"
      >
        <CancelService
          serviceBookingId={serviceBookingId}
          handleClose={() => handleCancelService(serviceBookingId)}
          setServiceData={setServiceData}
        />
      </Drawer>
      {/* Popup to show when cancel time frame is over */}
      <Drawer
        isOpen={withdrawService}
        handleClose={() => handleWithdrawClick(serviceBookingId)}
        title="Withdraw Service"
      >
        <WithdrawService
          serviceBookingId={serviceBookingId}
          handleClose={() => handleWithdrawClick(serviceBookingId)}
          setServiceData={setServiceData}
        />
      </Drawer>

      <Dialog
        open={showCancelMessage}
        onClose={handleClosePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancellation Unavailable"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The cancellation time frame has expired, and you are no longer able
            to cancel this service.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MyServices;
