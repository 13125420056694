import { Stack } from "@mui/system";
import AddService from "../../../component/service/AddService";
import { Box, Button, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListingTable from "../../../component/ListingTable";
import { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import Services from "../../../../../api/services";
import Swal from "sweetalert2";
import Drawer from "../../Drawer";
import ServiceEdit from "./serviceEdit";

interface Column {
  id:
    | "providerName"
    | "serviceName"
    | "serviceAria"
    | "year"
    | "price"
    | "description"
    | "status"
    | "action";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "providerName", label: "Provider Name", minWidth: 70 },
  { id: "serviceName", label: "Service Name", minWidth: 100 },
  { id: "description", label: "Description", minWidth: 200 },
  { id: "serviceAria", label: "Service Aria", minWidth: 120 },
  { id: "year", label: "Year In Business", minWidth: 50 },
  { id: "price", label: "Standard Price", minWidth: 50 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 170 },
];

interface Data {
  id: number;
  providerName: string;
  serviceName: string;
  serviceAria: string;
  year: string;
  price: string;
  description: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

function createData(
  id: number,
  providerName: string,
  serviceName: string,
  description: string,
  serviceAria: string,
  year: string,
  price: string,
  status: React.ReactNode,
  action: React.ReactNode
): Data {
  return {
    id,
    providerName,
    serviceName,
    description,
    serviceAria,
    year,
    price,
    status,
    action,
  };
}

interface UserData {
  id: number;
  serviceStatus: string;
}

const ListAllServices = () => {
  const [open, setOpen] = useState(false);
  const [serviceData, setServiceData] = useState([{}]);
  const [addService, setAddService] = useState(false);
  const [editService, setEditService] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    id: 0,
    serviceStatus: "",
  });

  const handleAddService = () => {
    setServiceData([{}]);
    setAddService(true);
  };

  const handleListService = async () => {
    await getServices();
    setAddService(false);
    setEditService(false);
  };

  const getServices = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.admin.getAllServiceData(options);
        setServiceData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleEditService = async (id: number) => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.provider.viewServiceDataById(
          id,
          options
        );
        if (response.status === 200) {
          const data = response?.data?.data;
          setServiceData(data);
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors) {
        Swal.fire({
          title: "Error!",
          text: `${error.response.data.errors}`,
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      }
      console.error("Error during POST request:", error);
    }

    setAddService(true);
    setEditService(true);
  };

  const handleRemoveService = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this service!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "#11b4f5",
      cancelButtonColor: "#ef5350",
      customClass: {
        cancelButton: "swal-cancel-button-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            const response = await Services.provider.deleteServiceDataById(
              id,
              options
            );
            if (response.status === 200) {
              getServices();
              Swal.fire("Deleted!", "Service has been deleted.", "success");
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Service is safe :)", "error");
      }
    });
  };

  const handleStatus = async (id: number, serviceStatus: string) => {
    setUserData({
      id,
      serviceStatus,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rows = serviceData.map((item: any) => {
    const rowData = {
      id: item.id,
      providerName: `${item.first_name} ${item.last_name}`,
      serviceName: item.service,
      serviceDesc: item.standard_description,
      serviceArea: item.service_area,
      yearInBusiness: item.business_year,
      standardPrice: item.standard_price,
      status: item.status,
    };

    return createData(
      rowData.id,
      rowData.providerName,
      rowData.serviceName,
      rowData.serviceDesc,
      rowData.serviceArea,
      rowData.yearInBusiness,
      rowData.standardPrice,
      <Button
        
        sx={{
          cursor: "pointer",
          color:
            rowData.status === "pending"
              ? "#11b4f5"
              : rowData.status === "approve"
              ? "#008000"
              : "#FF0000",
        }}
        onClick={() => handleStatus(rowData.id, rowData.status)}
      >
        {rowData.status}
      </Button>,
      <Stack direction={"row"} spacing={1}>
        <Button
          variant="contained"
          onClick={() => handleEditService(item.id)}
          size="small"
        >
          Edit
        </Button>
        <Button
          onClick={() => handleRemoveService(item.id)}
          sx={{
            textDecoration: "none",
            background: "#ef5350",
            color: "#fff",
            borderRadius: "30px",
            textTransform: "uppercase",
            padding: "6px 16px",
            "&:hover": {
              background: "#ef5350",
              color: "#fff",
            },
          }}
        >
          Remove
        </Button>
      </Stack>
    );
  });

  return (
    <>
      <Stack
        useFlexGap
        direction={"row"}
        spacing={2}
        justifyContent="space-between"
        display="flex"
        marginBottom={2}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            mb: 1,
            "@media(max-width: 768px)": {
              fontSize: "18px",
            },
          }}
        >
          {addService
            ? editService
              ? "Edit Service"
              : "Add Service"
            : "All Services"}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            mb: 1,
            alignItems: "center",
            display: "flex",
          }}
        >
          {addService ? (
            <Button variant="contained" onClick={handleListService}>
              <ListIcon sx={{ marginRight: "5px" }} />
              List service
            </Button>
          ) : (
            <Button variant="contained" onClick={handleAddService}>
              <AddCircleOutlineIcon sx={{ marginRight: "5px" }} />
              Add a service
            </Button>
          )}
        </Typography>
      </Stack>
      {addService ? (
        <AddService
          setAddService={handleListService}
          setServiceData={setServiceData}
          serviceData={serviceData}
          editService={editService}
        />
      ) : (
        <ListingTable
          columns={columns}
          rows={rows}
          shouldShowPagination={true}
        />
      )}

      <Drawer isOpen={open} handleClose={handleClose} title="Update Status">
        <ServiceEdit
          userData={userData}
          setServiceData={setServiceData}
          handleClose={handleClose}
        />
      </Drawer>
    </>
  );
};

export default ListAllServices;
