import { List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Drawer from "../../component/Drawer";
import DriverLicense from "../../component/documents/DriverLicense";
import WorkEligibility from "../../component/documents/WorkEligibility";
import SinNumber from "../../component/documents/SinNumber";
import ProvidersAgreement from "../../component/documents/ProvidersAgreement";
import CompanyRegistration from "../../component/documents/CompanyRegistration";

const Documents: React.FC = () => {
  const driverLicenseStatus: string = "pending";
  const workEligibilityStatus: string = "pending";
  const sinNumberStatus: string = "pending";
  const backgroundCheckStatus: string = "pending";
  const providersAgreementStatus: string = "pending";
  const companyRegistrationStatus: string = "pending";

  const [driverLicense, setDriverLicense] = useState(false);
  const [workEligibility, setWorkEligibility] = useState(false);
  const [sinNumber, setSinNumber] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [providersAgreement, setProvidersAgreement] = useState(false);
  const [companyRegistration, setCompanyRegistration] = useState(false);

  const handleDriverLicense = () => {
    setDriverLicense(!driverLicense);
  };

  const handleWorkEligibility = () => {
    setWorkEligibility(!workEligibility);
  };

  const handleSinNumber = () => {
    setSinNumber(!sinNumber);
  };

  const handleBackgroundCheck = () => {
    setBackgroundCheck(!backgroundCheck);
  };

  const handleProvidersAgreement = () => {
    setProvidersAgreement(!providersAgreement);
  };

  const handleCompanyRegistration = () => {
    setCompanyRegistration(!companyRegistration);
  };
  return (
    <>
      <Box sx={{ py: 2 }}>
        <Typography variant="h2" sx={{ fontSize: "24px", mb: 3 }}>
          Documents
        </Typography>

        <List
          sx={{
            "& .MuiListItem-root": {
              px: 0,
            },
            "& .MuiListItem-root span": {
              color: "#11b4f5",
              ml: 1,
              cursor: "pointer",
            },
          }}
        >
          <ListItem>
            1. Driver License
            {driverLicenseStatus && driverLicenseStatus === "pending" ? (
              <Typography component="span" onClick={handleDriverLicense}>
                Click here
              </Typography>
            ) : (
              <Typography component="span">Waiting for approval</Typography>
            )}
          </ListItem>
          <ListItem>
            2. Proof Of Work Eligibility
            {workEligibilityStatus && workEligibilityStatus === "pending" ? (
              <Typography component="span" onClick={handleWorkEligibility}>
                Click here
              </Typography>
            ) : (
              <Typography component="span">Waiting for approval</Typography>
            )}
          </ListItem>
          <ListItem>
            3. SIN Number (Optional)
            {sinNumberStatus && sinNumberStatus === "pending" ? (
              <Typography component="span" onClick={handleSinNumber}>
                Click here
              </Typography>
            ) : (
              <Typography component="span">Waiting for approval</Typography>
            )}
          </ListItem>
          <ListItem>
            4. Background Check
            {backgroundCheckStatus && backgroundCheckStatus === "pending" ? (
              <Typography component="span" onClick={handleBackgroundCheck}>
                Click here
              </Typography>
            ) : (
              <Typography component="span">Waiting for approval</Typography>
            )}
          </ListItem>
          <ListItem>
            5. Service Providers Agreement
            {providersAgreementStatus &&
            providersAgreementStatus === "pending" ? (
              <Typography
                component="span"
                onClick={handleProvidersAgreement}
                sx={{ whiteSpace: "nowrap" }}
              >
                Click here
              </Typography>
            ) : (
              <Typography component="span">Waiting for approval</Typography>
            )}
          </ListItem>
          <ListItem>
            6. Company Registration (Optional)
            {companyRegistrationStatus &&
            companyRegistrationStatus === "pending" ? (
              <Typography
                component="span"
                onClick={handleCompanyRegistration}
                sx={{ whiteSpace: "nowrap" }}
              >
                Click here
              </Typography>
            ) : (
              <Typography component="span">Waiting for approval</Typography>
            )}
          </ListItem>
        </List>
      </Box>
      <Drawer
        isOpen={driverLicense}
        handleClose={handleDriverLicense}
        title="Driver License"
      >
        <DriverLicense handleClose={handleDriverLicense} />
      </Drawer>
      <Drawer
        isOpen={workEligibility}
        handleClose={handleWorkEligibility}
        title="Proof Of Work Eligibility"
      >
        <WorkEligibility handleClose={handleWorkEligibility} />
      </Drawer>
      <Drawer
        isOpen={sinNumber}
        handleClose={handleSinNumber}
        title="SIN Number"
      >
        <SinNumber handleClose={handleSinNumber} />
      </Drawer>
      <Drawer
        isOpen={backgroundCheck}
        handleClose={handleBackgroundCheck}
        title="Please check your email for details regarding background screening"
      ></Drawer>
      <Drawer
        isOpen={providersAgreement}
        handleClose={handleProvidersAgreement}
        title="Service Providers Agreement"
      >
        <ProvidersAgreement handleClose={handleProvidersAgreement} />
      </Drawer>
      <Drawer
        isOpen={companyRegistration}
        handleClose={handleCompanyRegistration}
        title="Company Registration"
      >
        <CompanyRegistration handleClose={handleCompanyRegistration} />
      </Drawer>
    </>
  );
};

export default Documents;
