import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import Sidebar from "./component/Sidebar";
import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { IMAGES } from "../../assets/images";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";

const drawerWidth = 240;

const MainDashboard = () => {
  const userType = useSelector((state: any) => state.userData.user_type);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <>
      <AppBar
        component="nav"
        sx={{
          background: "#fff",
          "&.MuiPaper-root": { height: "63px" },
          zIndex: 999,
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component={Link} to={"/"}>
            <img alt="Logo" src={IMAGES.Logo} />
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          minHeight: "calc(100vh - 170px)",
          paddingTop: "67px",
          "@media (max-width:768px)": {
            display: "inherit",
          },
        }}
      >
        <Box
          sx={{
            minHeight: "100%",

            width: { md: drawerWidth },
            flexShrink: { md: 0 },
          }}
          component="nav"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                paddingTop: "15px",
              },
            }}
          >
            <Sidebar handleDrawerClose={handleDrawerClose} />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                top: "63px",
                borderRight: "0px",
                boxShadow: "7px 0 60px rgba(0,0,0,.05)",
                zIndex: 99,
              },
            }}
            open
          >
            <Sidebar handleDrawerClose={handleDrawerClose} />
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { md: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export default MainDashboard;
