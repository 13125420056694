const serviceNames = [
  {
    id: 1,
    name: "Snow Removal - Residential",
    standardServiceDesc:
      "Standard service includes removal of fresh fell snaw from a 2 car driveway upto 8 inches of snow excluding black ice scrapping.",
  },
  {
    id: 12,
    name: "Snow Removal - Commercial",
    standardServiceDesc:
      "Standard service includes removal of fresh fell snaw from a 2 car driveway upto 8 inches of snow excluding black ice scrapping.",
  },
  {
    id: 2,
    name: "House Cleaning - Residential",
    standardServiceDesc:
      "Standard service includes 1 bedroom, 1 kitchen, 1 living room, entry & hallways.",
  },
  {
    id: 13,
    name: "House Cleaning - Commercial",
    standardServiceDesc:
      "Standard service includes 1 bedroom, 1 kitchen, 1 living room, entry & hallways.",
  },
  {
    id: 3,
    name: "In Home Salon - Men/Kids",
    standardServiceDesc: "Standard service includes regular haircut.",
  },
  {
    id: 4,
    name: "In Home Salon - Women",
    standardServiceDesc:
      "Standard service includes regular haircut / Facial / Full body wax / Threading.",
  },
  {
    id: 5,
    name: "Driving Instructors - 6/6",
    standardServiceDesc: "Standard service includes 6/6 certificate.",
  },
  {
    id: 6,
    name: "Driving Instructors - Hourly",
    standardServiceDesc:
      "Standard service includes 1 hour of driving lesson on road.",
  },
  {
    id: 7,
    name: "Academic Helpers - In Person",
    standardServiceDesc:
      "Standard service includes one hour in-person session with doubts & discussions.",
  },
  {
    id: 8,
    name: "Academic Helpers - Online",
    standardServiceDesc:
      "Standard service includes one hour online session with doubts & discussion.",
  },
  {
    id: 9,
    name: "Lawn Care",
    standardServiceDesc:
      "Transforming your residential oasis with precision lawn care, where expertise meets pristine landscapes.",
  },
  {
    id: 10,
    name: "Mobile Car detailing - Full Detailing",
    standardServiceDesc:
      "Full interior plus exterior detailing of a standard size sedan car.",
  },
  {
    id: 11,
    name: "Mobile Car detailing - Interior Only",
    standardServiceDesc:
      "Full interior plus exterior detailing of a standard size sedan car.",
  },
];

export default serviceNames;
