import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../../../component/input";
import Swal from "sweetalert2";
import Services from "../../../../api/services";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/system";

interface workEligibilityProps {
  id: number;
  work_doc_front: any;
  work_doc_back: any;
  document_type: any;
  allow_background_check: number;
}

interface props {
  handleClose: (value: boolean) => void;
}

const WorkEligibility: React.FC<props> = ({ handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<workEligibilityProps>>({});
  const [formData, setFormData] = useState<workEligibilityProps>({
    id: 0,
    work_doc_front: "",
    work_doc_back: "",
    document_type: "",
    allow_background_check: 1,
  });

  const [WorkDocumentFront, setWorkDocumentFront] = useState<string | null>(
    null
  );
  const [WorkDocumentBack, setWorkDocumentBack] = useState<string | null>(null);

  const validateForm = () => {
    const newErrors: Partial<workEligibilityProps> = {};

    if (!formData.work_doc_front) {
      newErrors.work_doc_front = "Work Eligibility Front is required";
    }

    if (!formData.work_doc_back) {
      newErrors.work_doc_back = "Work Eligibility Back is required";
    }

    if (!formData.document_type.trim()) {
      newErrors.document_type = "Document Type is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement; // Explicit casting to HTMLInputElement
    if (files && files.length > 0) {
      // Handle file selection
      const selectedFile = files[0];
      setFormData((prevData) => ({ ...prevData, [name]: selectedFile }));
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          if (name === "work_doc_front") {
            setWorkDocumentFront(event.target.result as string);
          } else {
            setWorkDocumentBack(event.target.result as string);
          }
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Handle other input types
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const removeImage = (imageType: "front" | "back") => {
    if (imageType === "front") {
      setWorkDocumentFront(null);
      const frontInput = document.querySelector(
        'input[name="work_doc_front"]'
      ) as HTMLInputElement;
      if (frontInput) {
        frontInput.value = ""; // Clear the input value
      }
    } else {
      setWorkDocumentBack(null);
      const backInput = document.querySelector(
        'input[name="work_doc_back"]'
      ) as HTMLInputElement;
      if (backInput) {
        backInput.value = ""; // Clear the input value
      }
    }
  };

  const handleSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      var data = new FormData();
      data.append("WorkDocFront", formData.work_doc_front);
      data.append("WorkDocBack", formData.work_doc_back);
      data.append("document_type", formData.document_type);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateWorkEligibilityService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Work eligibility has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const fetchWorkEligibility = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getWorkEligibilityService(
          options
        );

        setFormData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchWorkEligibility();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={12} lg={6}>
            <FormGroup
              sx={{
                my: 2,
                "& img": {
                  mt: 1,
                  maxHeight: "150px",
                  maxWidth: "150px",
                  objectFit: "cover",
                },
              }}
            >
              <FormLabel sx={{ mb: 1 }}>Upload photo Front</FormLabel>
              <Input
                type="file"
                name="work_doc_front"
                // value={formData.work_doc_front}
                onChange={handleInputChange}
              />

              {formData.work_doc_front ? (
                <img
                  src={
                    formData.work_doc_front
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${formData.work_doc_front}`
                      : ""
                  }
                  alt={""}
                />
              ) : (
                ""
              )}

              {WorkDocumentFront && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                    onClick={() => removeImage("front")}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={WorkDocumentFront}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.work_doc_front && (
                <Typography color="error">{errors.work_doc_front}</Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormGroup
              sx={{
                my: 2,
                "& img": {
                  mt: 1,
                  maxHeight: "150px",
                  maxWidth: "150px",
                  objectFit: "cover",
                },
              }}
            >
              <FormLabel sx={{ mb: 1 }}>Upload Photo Back</FormLabel>
              <Input
                type="file"
                name="work_doc_back"
                // value={formData.work_doc_back}
                onChange={handleInputChange}
              />

              {formData.work_doc_back ? (
                <img
                  src={
                    formData.work_doc_back
                      ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${formData.work_doc_back}`
                      : ""
                  }
                  alt={""}
                />
              ) : (
                ""
              )}
              {WorkDocumentBack && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => removeImage("back")}
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={WorkDocumentBack}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.work_doc_back && (
                <Typography color="error">{errors.work_doc_back}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>
                What document are you providing
              </FormLabel>
              <Select
                fullWidth
                name="document_type"
                value={formData.document_type}
                label="trade"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    width: "100%",
                    fontSize: "14px",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={"studyPermit"}>Study Permit</MenuItem>
                <MenuItem value={"workPermit"}>Work Permit</MenuItem>
                <MenuItem value={"prCard"}>PR Card</MenuItem>
                <MenuItem value={"citizen"}>Citizen (Provincial ID)</MenuItem>
              </Select>
              {errors.document_type && (
                <Typography color="error">{errors.document_type}</Typography>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {formData.id !== 0 &&
          (formData.allow_background_check === 1 ||
            formData.allow_background_check === 2) ? (
            <Typography variant="h6" sx={{ mt: 2, color: "red" }}>
              Note: Documents is under review yet
            </Typography>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disabled={
                (formData.id !== 0 &&
                  (formData.allow_background_check === 1 ||
                    formData.allow_background_check === 2)) ||
                isSubmitted
              }
            >
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default WorkEligibility;
